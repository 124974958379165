import React, { useState } from "react";

export const ProductQuantity = () => {
  const [quantity, setQuantity] = useState(1);

  const handlePlusClick = () => {
    setQuantity((prev) => prev + 1);
  };

  const handleMinusClick = () => {
    if (quantity === 1) {
      return;
    }
    setQuantity((prev) => prev - 1);
  };

  return (
    <div className="flex flex-row justify-between items-center">
      <div className="font-bold text-primary text-xl">Quantity</div>
      <div className="flex flex-row">
        <div
          className="flex items-center py-1 px-3 text-text font-bold text-2xl border border-gray-300 cursor-pointer"
          onClick={handlePlusClick}
          draggable={false}
        >
          +
        </div>
        <div className="flex items-center justify-center pt-1 w-[50px] text-text font-bold border border-gray-300">
          {quantity}
        </div>
        <div
          className="flex items-center py-1 px-3 text-text font-bold text-2xl border border-gray-300 cursor-pointer"
          onClick={handleMinusClick}
          draggable={false}
        >
          -
        </div>
      </div>
    </div>
  );
};
