import { Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/home_page/HomePage";
import { ProductPage } from "./pages/product_page/ProductPage";
import { CategoryPage } from "./pages/category_page/CategoryPage";
import useScrollToTop from "./hooks/useScrollToTop";
import { CartPage } from "./pages/cart/CartPage";
import { CheckoutPage } from "./pages/checkout/CheckoutPage";

function App() {
  useScrollToTop();
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/product/:productId" element={<ProductPage />} />
      <Route path="/category/:categoryId" element={<CategoryPage />} />
      <Route path="/cart" element={<CartPage />} />
      <Route path="/checkout" element={<CheckoutPage />} />
    </Routes>
  );
}

export default App;
