import React, { useState } from "react";
import colors from "../util/colors";

export const FooterContactUsButton = ({ text, genIcon, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      className="flex flex-row rounded-md px-3 pt-2 pb-1 border text-sm border-text hover:border-gray-400 text-text hover:text-gray-400 cursor-pointer space-x-1"
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
     {isHovered ? genIcon("#9ca3af") : genIcon(colors.text)}
      <div className="container flex-row items-center">
        {text}
      </div>
    </div>
  );
};
