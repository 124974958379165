import React from "react";

export const iconBurgerMenu = (
  <svg
    className="w-6 h-6"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 6h16M4 12h16m-7 6h7"
    ></path>
  </svg>
);

export function iconClose(props) {
  return (
    <svg
      className="w-6 h-6"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        stroke={props?.fillColor ? props.fillColor : ""}
        d="M6 18L18 6M6 6l12 12"
      ></path>
    </svg>
  );
}

export function iosArrowDown(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"
        fill={props?.fillColor ? props.fillColor : ""}
      ></path>
    </svg>
  );
}

export function iosArrowRight(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M11.273 3.687a1 1 0 1 1 1.454-1.374l8.5 9a1 1 0 0 1 0 1.374l-8.5 9.001a1 1 0 1 1-1.454-1.373L19.125 12z"
      ></path>
    </svg>
  );
}

export function iconHeartEmpty(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width ? props.width : "1em"}
      height={props?.height ? props.height : "1em"}
      viewBox="0 0 24 24"
    >
      <path
        fill={props?.fillColor ? props.fillColor : ""}
        d="m8.962 18.91.464-.588-.464.589ZM12 5.5l-.54.52a.75.75 0 0 0 1.08 0L12 5.5Zm3.038 13.41.465.59-.465-.59Zm-5.612-.588C7.91 17.127 6.253 15.96 4.938 14.48 3.65 13.028 2.75 11.334 2.75 9.137h-1.5c0 2.666 1.11 4.7 2.567 6.339 1.43 1.61 3.254 2.9 4.68 4.024l.93-1.178ZM2.75 9.137c0-2.15 1.215-3.954 2.874-4.713 1.612-.737 3.778-.541 5.836 1.597l1.08-1.04C10.1 2.444 7.264 2.025 5 3.06 2.786 4.073 1.25 6.425 1.25 9.137h1.5ZM8.497 19.5c.513.404 1.063.834 1.62 1.16.557.325 1.193.59 1.883.59v-1.5c-.31 0-.674-.12-1.126-.385-.453-.264-.922-.628-1.448-1.043L8.497 19.5Zm7.006 0c1.426-1.125 3.25-2.413 4.68-4.024 1.457-1.64 2.567-3.673 2.567-6.339h-1.5c0 2.197-.9 3.891-2.188 5.343-1.315 1.48-2.972 2.647-4.488 3.842l.929 1.178ZM22.75 9.137c0-2.712-1.535-5.064-3.75-6.077-2.264-1.035-5.098-.616-7.54 1.92l1.08 1.04c2.058-2.137 4.224-2.333 5.836-1.596 1.659.759 2.874 2.562 2.874 4.713h1.5Zm-8.176 9.185c-.526.415-.995.779-1.448 1.043-.452.264-.816.385-1.126.385v1.5c.69 0 1.326-.265 1.883-.59.558-.326 1.107-.756 1.62-1.16l-.929-1.178Z"
      />
    </svg>
  );
}

export function iconEye(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width ? props.width : "1em"}
      height={props?.height ? props.height : "1em"}
      viewBox="0 0 24 24"
    >
      <path
        fill={props?.fillColor ? props.fillColor : ""}
        fill-rule="evenodd"
        d="M12 8.25a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5ZM9.75 12a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0Z"
        clip-rule="evenodd"
      />
      <path
        fill={props?.fillColor ? props.fillColor : ""}
        fill-rule="evenodd"
        d="M12 3.25c-4.514 0-7.555 2.704-9.32 4.997l-.031.041c-.4.519-.767.996-1.016 1.56-.267.605-.383 1.264-.383 2.152 0 .888.116 1.547.383 2.152.25.564.617 1.042 1.016 1.56l.032.041C4.445 18.046 7.486 20.75 12 20.75c4.514 0 7.555-2.704 9.32-4.997l.031-.041c.4-.518.767-.996 1.016-1.56.267-.605.383-1.264.383-2.152 0-.888-.116-1.547-.383-2.152-.25-.564-.617-1.041-1.016-1.56l-.032-.041C19.555 5.954 16.514 3.25 12 3.25ZM3.87 9.162C5.498 7.045 8.15 4.75 12 4.75c3.85 0 6.501 2.295 8.13 4.412.44.57.696.91.865 1.292.158.358.255.795.255 1.546s-.097 1.188-.255 1.546c-.169.382-.426.722-.864 1.292C18.5 16.955 15.85 19.25 12 19.25c-3.85 0-6.501-2.295-8.13-4.412-.44-.57-.696-.91-.865-1.292-.158-.358-.255-.795-.255-1.546s.097-1.188.255-1.546c.169-.382.426-.722.864-1.292Z"
        clip-rule="evenodd"
      />
    </svg>
  );
}

export function iconBag(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width ? props.width : "1em"}
      height={props?.height ? props.height : "1em"}
      viewBox="0 0 24 24"
    >
      <path
        stroke={props?.fillColor ? props.fillColor : ""}
        fill={props?.fillColor ? props.fillColor : ""}
        stroke-width="1"
        d="M3.742 18.555C4.942 20 7.174 20 11.639 20h.722c4.465 0 6.698 0 7.898-1.445m-16.517 0c-1.2-1.446-.788-3.64.035-8.03.585-3.12.877-4.681 1.988-5.603M3.742 18.555Zm16.517 0c1.2-1.446.788-3.64-.035-8.03-.585-3.12-.878-4.681-1.989-5.603m2.024 13.633ZM18.235 4.922C17.125 4 15.536 4 12.361 4h-.722c-3.175 0-4.763 0-5.874.922m12.47 0Zm-12.47 0Z"
      />
      <path
        stroke={props?.fillColor ? props.fillColor : ""}
        fill={props?.fillColor ? props.fillColor : ""}
        stroke-linecap="round"
        stroke-width="1.5"
        d="M9.17 8a3.001 3.001 0 0 0 5.66 0"
      />
    </svg>
  );
}

export function iconWhatsapp(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width ? props.width : "1em"}
      height={props?.height ? props.height : "1em"}
      viewBox="0 0 32 32"
    >
      <path
        fill={props?.fillColor ? props.fillColor : ""}
        d="M26.576 5.363a14.818 14.818 0 0 0-10.511-4.354C7.856 1.009 1.2 7.664 1.2 15.874c0 2.732.737 5.291 2.022 7.491l-.038-.07-2.109 7.702 7.879-2.067c2.051 1.139 4.498 1.809 7.102 1.809h.006c8.209-.003 14.862-6.659 14.862-14.868a14.82 14.82 0 0 0-4.349-10.507zM16.062 28.228h-.006c-2.319 0-4.489-.64-6.342-1.753l.056.031-.451-.267-4.675 1.227 1.247-4.559-.294-.467a12.23 12.23 0 0 1-1.889-6.565c0-6.822 5.531-12.353 12.353-12.353s12.353 5.531 12.353 12.353-5.53 12.353-12.353 12.353zm6.776-9.251c-.371-.186-2.197-1.083-2.537-1.208-.341-.124-.589-.185-.837.187-.246.371-.958 1.207-1.175 1.455-.216.249-.434.279-.805.094a10.23 10.23 0 0 1-2.997-1.852l.01.009a11.236 11.236 0 0 1-2.037-2.521l-.028-.052c-.216-.371-.023-.572.162-.757.167-.166.372-.434.557-.65.146-.179.271-.384.366-.604l.006-.017a.678.678 0 0 0-.033-.653l.002.003c-.094-.186-.836-2.014-1.145-2.758-.302-.724-.609-.625-.836-.637-.216-.01-.464-.012-.712-.012-.395.01-.746.188-.988.463l-.001.002a4.153 4.153 0 0 0-1.299 3.102v-.004a7.233 7.233 0 0 0 1.527 3.857l-.012-.015a16.693 16.693 0 0 0 6.251 5.564l.094.043c.548.248 1.25.513 1.968.74l.149.041a5.103 5.103 0 0 0 2.368.143l-.031.004a3.837 3.837 0 0 0 2.497-1.749l.009-.017a3.122 3.122 0 0 0 .214-1.784l.003.019c-.092-.155-.34-.247-.712-.434z"
      />
    </svg>
  );
}

export function iconPhone(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width ? props.width : "1em"}
      height={props?.height ? props.height : "1em"}
      viewBox="0 0 1024 1024"
    >
      <path
        fill={props?.fillColor ? props.fillColor : ""}
        d="M224 768v96.064a64 64 0 0 0 64 64h448a64 64 0 0 0 64-64V768H224zm0-64h576V160a64 64 0 0 0-64-64H288a64 64 0 0 0-64 64v544zm32 288a96 96 0 0 1-96-96V128a96 96 0 0 1 96-96h512a96 96 0 0 1 96 96v768a96 96 0 0 1-96 96H256zm304-144a48 48 0 1 1-96 0 48 48 0 0 1 96 0z"
      />
    </svg>
  );
}

export function iconEmail(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width ? props.width : "1em"}
      height={props?.height ? props.height : "1em"}
      viewBox="0 0 24 24"
    >
      <path
        fill={props?.fillColor ? props.fillColor : ""}
        fill-rule="evenodd"
        d="M3.75 5.25 3 6v12l.75.75h16.5L21 18V6l-.75-.75H3.75Zm.75 2.446v9.554h15V7.695L12 14.514 4.5 7.696Zm13.81-.946H5.69L12 12.486l6.31-5.736Z"
        clip-rule="evenodd"
      />
    </svg>
  );
}

export function iconCheck(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width ? props.width : "1em"}
      height={props?.height ? props.height : "1em"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke={props?.fillColor ? props.fillColor : ""}
        stroke-width="1.5"
      />
      <path
        stroke={props?.fillColor ? props.fillColor : ""}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="m8.5 12.5 2 2 5-5"
      />
    </svg>
  );
}

export function iconWallet(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width ? props.width : "1em"}
      height={props?.height ? props.height : "1em"}
      viewBox="0 -2.5 46 46"
    >
      <path
        fill={props?.fillColor ? props.fillColor : ""}
        fill-rule="evenodd"
        d="M46 35.994a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5v-29a4 4 0 0 1 4-4h20.171l8.1-2.934a.99.99 0 0 1 1.268.589l.852 2.345H38a4 4 0 0 1 4 4v2a4 4 0 0 1 4 4Zm-42-31a2 2 0 0 0 0 4h3.634c.013-.005.021-.016.034-.021L18.65 4.994Zm29.078.181-1.062-2.924-2.052.743-5.511 2h-.01l-11.043 4h21.066ZM40 6.994a2 2 0 0 0-2-2h-2.883l1.454 4H40Zm2 4H4a3.955 3.955 0 0 1-2-.555v25.555a3 3 0 0 0 3 3h36a3 3 0 0 0 3-3v-7h-4a4 4 0 0 1 0-8h4v-8a2 2 0 0 0-2-2Zm2 16v-4h-4a2 2 0 0 0 0 4Zm-4-3h2v2h-2Z"
        data-name="07.Wallet"
      />
    </svg>
  );
}

export function iconBarcode(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width ? props.width : "1em"}
      height={props?.height ? props.height : "1em"}
      viewBox="0 0 512 512"
    >
      <path
        fill={props?.fillColor ? props.fillColor : ""}
        d="M0 73.956h34.133v364.089H0zM477.867 73.956H512v364.089h-34.133zM79.644 73.956h34.133v295.822H79.644zM79.644 403.911h34.133v34.133H79.644zM159.289 73.956h34.133v295.822h-34.133zM159.289 403.911h34.133v34.133h-34.133zM238.933 73.956h34.133v295.822h-34.133zM238.933 403.911h34.133v34.133h-34.133zM318.578 73.956h34.133v295.822h-34.133zM318.578 403.911h34.133v34.133h-34.133zM398.222 73.956h34.133v295.822h-34.133zM398.222 403.911h34.133v34.133h-34.133z"
      />
    </svg>
  );
}

export function iconShare(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width ? props.width : "1em"}
      height={props?.height ? props.height : "1em"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clip-path="url(#a)">
        <path d="M0 0h24v24H0z" />
        <circle
          cx="7"
          cy="12"
          r="2"
          stroke={props?.fillColor ? props.fillColor : ""}
          stroke-linejoin="round"
        />
        <circle
          cx="17"
          cy="6"
          r="2"
          stroke={props?.fillColor ? props.fillColor : ""}
          stroke-linejoin="round"
        />
        <path
          stroke={props?.fillColor ? props.fillColor : ""}
          d="m15 7-6.5 4"
        />
        <circle
          cx="17"
          cy="18"
          r="2"
          stroke={props?.fillColor ? props.fillColor : ""}
          stroke-linejoin="round"
        />
        <path
          stroke={props?.fillColor ? props.fillColor : ""}
          d="M8.5 13.5 15 17"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function getFormattedPrice(price) {
  const currency = "SAR";
  return `${price} ${currency}`;
}
