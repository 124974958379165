import React, { useState } from "react";
import { getFormattedPrice, iconClose } from "../../util/ui_util";

export const ItemCart = ({ item, onDelete }) => {
  const [quantity, setQuantity] = useState(item.quantity);

  const handlePlusClick = () => {
    setQuantity((prev) => prev + 1);
  };

  const handleMinusClick = () => {
    if (quantity === 1) {
      return;
    }
    setQuantity((prev) => prev - 1);
  };

  const productInfo = () => {
    return (
      <div className="flex flex-row w-[240px] space-x-2 mt-4">
        <img
          src={item.product.images[0]}
          alt=""
          className="w-[96px] rounded-md"
        />
        <div className="flex flex-col">
          <div className="text-text font-bold">{item.product.name}</div>
          <div className="text-text font-normal text-sm">
            {getFormattedPrice(item.product.price)}
          </div>
        </div>
      </div>
    );
  };

  const quantityControl = () => {
    return (
      <div className="flex flex-row h-[40px] border rounded-md border-gray-300 divide-x divide-gray-300 mt-4">
        <div
          className="flex items-center py-1 px-3 text-text font-bold text-2xl cursor-pointer"
          onClick={handleMinusClick}
          draggable={false}
        >
          -
        </div>
        <div className="flex items-center justify-center pt-1 w-[50px] text-text font-bold">
          {quantity}
        </div>
        <div
          className="flex items-center py-1 px-3 text-text font-bold text-2xl cursor-pointer"
          onClick={handlePlusClick}
          draggable={false}
        >
          +
        </div>
      </div>
    );
  };

  const subtotal = () => {
    return (
      <div className="text-primary font-bold mt-4">{`Subtotal:${getFormattedPrice(
        item.subtotal
      )}`}</div>
    );
  };

  const deleteButton = () => {
    return (
      <div
        className="flex flex-row justify-center items-center h-[24px] w-[24px] md:h-[30px] md:w-[30px] p-1 md:p-2 rounded-full bg-red-500 hover:bg-red-600 cursor-pointer"
        onClick={onDelete}
      >
        {iconClose({ fillColor: "white" })}
      </div>
    );
  };

  return (
    <div className="flex flex-col md:flex-row justify-between rounded-xl border border-primary px-6 pt-4 pb-4 md:pb-16">
      <div className="sm:display-block md:hidden lg:hidden flex-row justify-end">
        {deleteButton()}
      </div>
      {productInfo()}
      <div className="hidden md:flex">{quantityControl()}</div>
      <div className="flex md:hidden flex-row justify-between items-center">
        {quantityControl()}
        {subtotal()}
      </div>
      <div className="hidden md:flex flex-row space-x-8">
        {subtotal()}
        {deleteButton()}
      </div>
    </div>
  );
};
