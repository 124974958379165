import React from "react";
import iconLogo from "../assets/images/logo.webp";
import { NavLink } from "react-router-dom";
import { iconEmail, iconPhone, iconWhatsapp } from "../util/ui_util";
import { FooterContactUsButton } from "./FooterContactUsButton";
import iconMada from "../assets/images/icon_mada.webp";
import iconMastercard from "../assets/images/icon_mastercard.webp";
import iconVisa from "../assets/images/icon_visa.webp";
import iconBank from "../assets/images/icon_bank.webp";
import iconApplePay from "../assets/images/icon_apple_pay.webp";
import iconTabby from "../assets/images/icon_tabby.webp";
import iconTamra from "../assets/images/icon_tamara.webp";

export const Footer = () => {
  const Link = (text, url) => {
    return (
      <NavLink to={url}>
        <div className="text-text hover:text-gray-400">{text}</div>
      </NavLink>
    );
  };

  const PaymentMethod = ({ src }) => {
    return (
      <div className="flex flex-row items-center bg-white rounded p-1">
        <img src={src} alt="" className="w-[40px]" />
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full bg-[#FCFCFC] divide-y divide-dashed divide-text">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row lg:space-x-44 py-4">
          <div className="flex flex-col lg:w-[200px] w-full space-y-2 items-center">
            <img src={iconLogo} alt="" className="w-[200px] object-contain" />
            <div className="text-text text-center text-xs w-[200px]">
              توت مؤسسة سعودية سجل تجاري رقم 4032149660 تمتلك العديد من المعارض
              ومتجر الكتروني لخدمة العملاء في جميع مناطق المملكة العربية
              السعودية.. توت فخامة وتميز
            </div>
          </div>
          <div className="mx-auto flex flex-row lg:w-[600px] justify-between">
            <div className="flex flex-col w-[200px] md:w-[240px] space-y-2 py-4">
              <div className="text-text font-bold text-xl">Important links</div>
              <div className="flex flex-col space-y-1">
                {Link("Privacy policy", "")}
                {Link("Refund policy", "")}
                {Link("Whoa are we", "")}
                {Link("Our branches", "")}
              </div>
            </div>
            <div className="flex flex-col w-[200px] md:w-[240px] space-y-2 py-4">
              <div className="text-text font-bold text-xl">Contact us</div>
              <div className="flex flex-wrap gap-y-2 gap-x-2">
                <div className="lg:flex-basis-[calc(50%-1.5rem)]">
                  {FooterContactUsButton({
                    text: "Whatsapp",
                    genIcon: (color) => {
                      return iconWhatsapp({ fillColor: color, width: "24px" });
                    },
                    onClick: () => {},
                  })}
                </div>
                <div className="lg:flex-basis-[calc(50%-1.5rem)]">
                  {FooterContactUsButton({
                    text: "Phone",
                    genIcon: (color) => {
                      return iconPhone({ fillColor: color, width: "24px" });
                    },
                    onClick: () => {},
                  })}
                </div>
                <div className="lg:flex-basis-[calc(50%-1.5rem)]">
                  {FooterContactUsButton({
                    text: "Email",
                    genIcon: (color) => {
                      return iconEmail({ fillColor: color, width: "24px" });
                    },
                    onClick: () => {},
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* bottom part */}
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between py-4">
        <div className="flex flex-row items-center space-x-1">
          {PaymentMethod({ src: iconMada })}
          {PaymentMethod({ src: iconMastercard })}
          {PaymentMethod({ src: iconVisa })}
          {PaymentMethod({ src: iconBank })}
          {PaymentMethod({ src: iconApplePay })}
          {PaymentMethod({ src: iconTabby })}
          {PaymentMethod({ src: iconTamra })}
        </div>
        <div className="text-text text-sm">
          Copyrights reserved | 2024 Toot mart
        </div>
      </div>
    </div>
  );
};
