import React from "react";
import { HomeCategory } from "./HomeCategory";
import { dummyCategory, homeSliderImages } from "../../util/dummy";
import { Layout } from "../../components/Layout";
import { HomeSlider } from "./HomeSlider";

export const HomePage = () => {
  return (
    <Layout>
      <div className="px-4 mt-4">
        <HomeSlider images={homeSliderImages} />
      </div>
      <div className="px-4 mt-4">
        <img
          src="https://cdn.salla.sa/form-builder/2aNkYFSJzVI2dIYUhKn1bslcANDX1T6XCjkTr4pZ.png"
          alt=""
          className="rounded-2xl object-cover w-full"
        />
      </div>
      <HomeCategory category={dummyCategory} />
      <div className="px-4 mt-4">
        <img
          src="https://cdn.salla.sa/form-builder/2aNkYFSJzVI2dIYUhKn1bslcANDX1T6XCjkTr4pZ.png"
          alt=""
          className="rounded-2xl object-cover w-full"
        />
      </div>
      <HomeCategory category={dummyCategory} />
      <div className="px-4 mt-4">
        <img
          src="https://cdn.salla.sa/form-builder/2aNkYFSJzVI2dIYUhKn1bslcANDX1T6XCjkTr4pZ.png"
          alt=""
          className="rounded-2xl object-cover w-full"
        />
      </div>
      <HomeCategory category={dummyCategory} />
    </Layout>
  );
};
