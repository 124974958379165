import React, { useState, useEffect } from "react";
import { Banner } from "./Banner";
import { NavigationBar } from "./NavigationBar";
import { Footer } from "./Footer";
import { dummyCategories } from "../util/dummy";
import { Drawer } from "./Drawer";

export const Layout = ({ children }) => {
  const allCategories = dummyCategories;
  const isRtl = false;
  const isDesktop = window.innerWidth >= 1024;

  const [isSticky, setIsSticky] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 150) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1024px)");
    const handleMediaQueryChange = (event) => {
      if (event.matches) {
        setIsOpen(false);
      }
    };
    mediaQuery.addEventListener("change", handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <div className="flex flex-col flex-grow">
        <Banner />
        <NavigationBar
          isDesktop={isDesktop}
          isRtl={isRtl}
          isOpen={isOpen}
          categories={allCategories}
          onBurgerMenuClick={() => {
            setIsOpen(!isOpen);
          }}
        />
        <div className="container mx-auto">{children}</div>
      </div>
      <Footer />
      <div
        className={`fixed top-0 left-0 w-full bg-white shadow-md z-50 transition-transform duration-300 transform ${
          isSticky ? "translate-y-0" : "-translate-y-full"
        }`}
      >
        <Banner />
        <NavigationBar
          isDesktop={isDesktop}
          isRtl={isRtl}
          isOpen={isOpen}
          categories={allCategories}
          onBurgerMenuClick={() => {
            setIsOpen(!isOpen);
          }}
        />
      </div>
      <Drawer
        isOpen={isOpen}
        isRtl={isRtl}
        allCategories={allCategories}
        onCloseClick={() => setIsOpen(false)}
      />
    </div>
  );
};
