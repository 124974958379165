import React, { useState } from "react";
import { MoonLoader } from "react-spinners";
import colors from "../../util/colors";

export const ItemHomeProductButton = ({
  icon,
  type,
  iconFilled,
  isLoading,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const spinner = () => (
    <MoonLoader
      color={isHovered ? "#ffffff" : colors.primary}
      loading={true}
      size={18}
    />
  );

  const handleClick = (e) => {
    if (isLoading) {
      e.preventDefault();
      return;
    }
    if (type !== "submit") {
      onClick();
    }
  };

  return (
    <div
      className={`flex items-center justify-center p-1 rounded-full border-2 border-primary cursor-pointer ${
        isHovered ? "bg-primary" : "bg-transparent"
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      {isLoading ? (
        spinner()
      ) : isHovered ? (
        <span>{iconFilled}</span>
      ) : (
        <span>{icon}</span>
      )}
    </div>
  );
};
