import React, { useState } from "react";
import { Layout } from "../../components/Layout";
import { dummyCart } from "../../util/dummy";
import { ItemCart } from "./ItemCart";
import { CartOrderReview } from "./CartOrderReview";

export const CartPage = () => {
  const [cart, setCart] = useState(dummyCart);

  const handleItemDeleted = (item) => {
    const updatedCart = cart.filter((i) => i.id !== item.id);
    setCart(updatedCart);
  };

  return (
    <Layout>
      <div className="flex flex-col px-4 py-8 space-y-4">
        <div className="flex flex-col lg:flex-row justify-between lg:space-x-2 space-y-4 lg:space-y-0">
          <div className="flex flex-col space-y-4 w-full">
            {cart.map((item, index) => (
              <ItemCart
                item={item}
                key={index}
                onDelete={() => {
                  handleItemDeleted(item);
                }}
              />
            ))}
          </div>
          <div className="flex flex-col">
            <CartOrderReview cart={cart} />
          </div>
        </div>
      </div>
    </Layout>
  );
};
