import React from "react";
import { getFormattedPrice } from "../../util/ui_util";
import { ButtonWithIcon } from "../../components/ButtonWithIcon";
import colors from "../../util/colors";

export const CartOrderReview = ({ cart }) => {
  const subtotal = cart.reduce((total, item) => {
    return total + item.subtotal;
  }, 0);

  return (
    <div className="flex flex-col space-y-2 bg-white p-4 md:w-full lg:w-[370px] shadow-lg">
      <div className="text-text font-bold">Order review</div>
      <div className="flex flex-row justify-between">
        <div className="text-text text-sm">Subtotal</div>
        <div className="text-text text-sm font-bold">
          {getFormattedPrice(subtotal)}
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="text-text">Total</div>
        <div className="text-text font-bold">{getFormattedPrice(subtotal)}</div>
      </div>
      <div className="text-text text-xs">*Price including tax</div>
      <ButtonWithIcon
        text="Checkout"
        textColor="#ffffff"
        textHoverColor="#ffffff"
        bgColor={colors.primary}
        bgHoverColor={colors.primary}
        getIcon={(color) => {}}
        onClick={() => {}}
      />
    </div>
  );
};
