export const dummyBannerData = [
  {
    id: 1,
    text: "Breaking News: New React features released!",
    url: "https://google.com",
  },
  {
    id: 2,
    text: "Update: Tailwind CSS v3.0 is now available!",
    url: "https://google.com",
  },
  {
    id: 3,
    text: "Announcement: Join our webinar on modern web development!",
    url: "https://google.com",
  },
];

export const homeSliderImages = [
  "https://cdn.salla.sa/form-builder/IwZx4hDmrHu6FAT8BcCpC20KD8BfXMyMM5gWNz71.jpg",
  "https://cdn.salla.sa/form-builder/IwZx4hDmrHu6FAT8BcCpC20KD8BfXMyMM5gWNz71.jpg",
  "https://cdn.salla.sa/form-builder/IwZx4hDmrHu6FAT8BcCpC20KD8BfXMyMM5gWNz71.jpg",
  "https://cdn.salla.sa/form-builder/IwZx4hDmrHu6FAT8BcCpC20KD8BfXMyMM5gWNz71.jpg",
];

export const dummyCategory = {
  id: 12,
  name: "Furniture",
  products: [
    {
      id: 1,
      name: "Modern couch",
      price: 490,
      price_with_discount: 490,
      discount_percentage: 0,
      is_new: true,
      model: "TF003 -2",
      images: [
        "https://cdn.salla.sa/mmgvo/5519e395-c80f-47e5-87b8-de3440348389-500x500-Gz9nUSajuM4LEgesWZLuY1fyb2c3IGPkppjRpYEq.jpg",
        "https://cdn.salla.sa/mmgvo/90ebe4ec-8c0a-4148-b6bf-fa60e3beea46-1000x1000-MN0dvHQDqVVSmoOSTeNv71bB3pK2rZxjPjrlRa19.jpg",
        "https://cdn.salla.sa/mmgvo/90ebe4ec-8c0a-4148-b6bf-fa60e3beea46-1000x1000-MN0dvHQDqVVSmoOSTeNv71bB3pK2rZxjPjrlRa19.jpg",
        "https://cdn.salla.sa/mmgvo/90ebe4ec-8c0a-4148-b6bf-fa60e3beea46-1000x1000-MN0dvHQDqVVSmoOSTeNv71bB3pK2rZxjPjrlRa19.jpg",
        "https://cdn.salla.sa/mmgvo/90ebe4ec-8c0a-4148-b6bf-fa60e3beea46-1000x1000-MN0dvHQDqVVSmoOSTeNv71bB3pK2rZxjPjrlRa19.jpg",
        "https://cdn.salla.sa/mmgvo/90ebe4ec-8c0a-4148-b6bf-fa60e3beea46-1000x1000-MN0dvHQDqVVSmoOSTeNv71bB3pK2rZxjPjrlRa19.jpg",
        "https://cdn.salla.sa/mmgvo/90ebe4ec-8c0a-4148-b6bf-fa60e3beea46-1000x1000-MN0dvHQDqVVSmoOSTeNv71bB3pK2rZxjPjrlRa19.jpg",
        "https://cdn.salla.sa/mmgvo/90ebe4ec-8c0a-4148-b6bf-fa60e3beea46-1000x1000-MN0dvHQDqVVSmoOSTeNv71bB3pK2rZxjPjrlRa19.jpg",
        "https://cdn.salla.sa/mmgvo/90ebe4ec-8c0a-4148-b6bf-fa60e3beea46-1000x1000-MN0dvHQDqVVSmoOSTeNv71bB3pK2rZxjPjrlRa19.jpg",
      ],
      description:
        '<p><strong>Modern Elegance Sofa</strong></p><p>Transform your living space with this sleek, <em>modern elegance sofa</em> designed to blend style and comfort effortlessly. Upholstered in high-quality fabric, this sofa features clean lines, tapered wooden legs, and plush cushions that provide the perfect balance of firm support and soft coziness. Whether you\'re entertaining guests or enjoying a cozy night in, this sofa is versatile enough to suit any occasion. Its neutral color palette complements any decor, while the minimalist design adds a touch of sophistication to your home.</p><p><strong>Dimensions:</strong> 85" W x 35" D x 32" H</p><p><strong>Material:</strong> Premium polyester fabric, hardwood frame, foam cushions</p><p><strong>Key Features:</strong></p><ul><li>Removable cushion covers for easy cleaning</li><li>Stain-resistant fabric</li><li>Durable hardwood frame</li><li>Tool-free assembly in minutes</li><li>Ergonomically designed for superior comfort</li></ul>',
    },
    {
      id: 2,
      name: "Bedding set 4 pieces",
      price: 6990,
      price_with_discount: 2990,
      discount_percentage: 60,
      is_new: false,
      model: "TF003 -2",
      images: [
        "https://cdn.salla.sa/mmgvo/c84d15fc-8671-4827-afc5-39ef78a238d0-500x500-owOwhFyUPQdiNWoAYhVvK5PVrf4KM0nt7k3tHPQv.jpg",
      ],
      description:
        '<p><strong>Modern Elegance Sofa</strong></p><p>Transform your living space with this sleek, <em>modern elegance sofa</em> designed to blend style and comfort effortlessly. Upholstered in high-quality fabric, this sofa features clean lines, tapered wooden legs, and plush cushions that provide the perfect balance of firm support and soft coziness. Whether you\'re entertaining guests or enjoying a cozy night in, this sofa is versatile enough to suit any occasion. Its neutral color palette complements any decor, while the minimalist design adds a touch of sophistication to your home.</p><p><strong>Dimensions:</strong> 85" W x 35" D x 32" H</p><p><strong>Material:</strong> Premium polyester fabric, hardwood frame, foam cushions</p><p><strong>Key Features:</strong></p><ul><li>Removable cushion covers for easy cleaning</li><li>Stain-resistant fabric</li><li>Durable hardwood frame</li><li>Tool-free assembly in minutes</li><li>Ergonomically designed for superior comfort</li></ul>',
    },
    {
      id: 3,
      name: "Bedding set 3 pieces",
      price: 5990,
      price_with_discount: 3490,
      discount_percentage: 50,
      is_new: true,
      model: "TF003 -2",
      images: [
        "https://cdn.salla.sa/mmgvo/506962ff-2224-4f56-96ce-f2f004ee75fb-500x500-eXvx2SfB3QWF5tDp9x9JLHUp9qibx0xNUEYBt7Zk.jpg",
      ],
      description:
        '<p><strong>Modern Elegance Sofa</strong></p><p>Transform your living space with this sleek, <em>modern elegance sofa</em> designed to blend style and comfort effortlessly. Upholstered in high-quality fabric, this sofa features clean lines, tapered wooden legs, and plush cushions that provide the perfect balance of firm support and soft coziness. Whether you\'re entertaining guests or enjoying a cozy night in, this sofa is versatile enough to suit any occasion. Its neutral color palette complements any decor, while the minimalist design adds a touch of sophistication to your home.</p><p><strong>Dimensions:</strong> 85" W x 35" D x 32" H</p><p><strong>Material:</strong> Premium polyester fabric, hardwood frame, foam cushions</p><p><strong>Key Features:</strong></p><ul><li>Removable cushion covers for easy cleaning</li><li>Stain-resistant fabric</li><li>Durable hardwood frame</li><li>Tool-free assembly in minutes</li><li>Ergonomically designed for superior comfort</li></ul>',
    },
    {
      id: 4,
      name: "Modern couch",
      price: 490,
      price_with_discount: 490,
      discount_percentage: 0,
      is_new: true,
      model: "TF003 -2",
      images: [
        "https://cdn.salla.sa/mmgvo/5519e395-c80f-47e5-87b8-de3440348389-500x500-Gz9nUSajuM4LEgesWZLuY1fyb2c3IGPkppjRpYEq.jpg",
      ],
      description:
        '<p><strong>Modern Elegance Sofa</strong></p><p>Transform your living space with this sleek, <em>modern elegance sofa</em> designed to blend style and comfort effortlessly. Upholstered in high-quality fabric, this sofa features clean lines, tapered wooden legs, and plush cushions that provide the perfect balance of firm support and soft coziness. Whether you\'re entertaining guests or enjoying a cozy night in, this sofa is versatile enough to suit any occasion. Its neutral color palette complements any decor, while the minimalist design adds a touch of sophistication to your home.</p><p><strong>Dimensions:</strong> 85" W x 35" D x 32" H</p><p><strong>Material:</strong> Premium polyester fabric, hardwood frame, foam cushions</p><p><strong>Key Features:</strong></p><ul><li>Removable cushion covers for easy cleaning</li><li>Stain-resistant fabric</li><li>Durable hardwood frame</li><li>Tool-free assembly in minutes</li><li>Ergonomically designed for superior comfort</li></ul>',
    },
    {
      id: 5,
      name: "Bedding set 4 pieces",
      price: 6990,
      price_with_discount: 2990,
      discount_percentage: 60,
      is_new: false,
      model: "TF003 -2",
      images: [
        "https://cdn.salla.sa/mmgvo/c84d15fc-8671-4827-afc5-39ef78a238d0-500x500-owOwhFyUPQdiNWoAYhVvK5PVrf4KM0nt7k3tHPQv.jpg",
      ],
      description:
        '<p><strong>Modern Elegance Sofa</strong></p><p>Transform your living space with this sleek, <em>modern elegance sofa</em> designed to blend style and comfort effortlessly. Upholstered in high-quality fabric, this sofa features clean lines, tapered wooden legs, and plush cushions that provide the perfect balance of firm support and soft coziness. Whether you\'re entertaining guests or enjoying a cozy night in, this sofa is versatile enough to suit any occasion. Its neutral color palette complements any decor, while the minimalist design adds a touch of sophistication to your home.</p><p><strong>Dimensions:</strong> 85" W x 35" D x 32" H</p><p><strong>Material:</strong> Premium polyester fabric, hardwood frame, foam cushions</p><p><strong>Key Features:</strong></p><ul><li>Removable cushion covers for easy cleaning</li><li>Stain-resistant fabric</li><li>Durable hardwood frame</li><li>Tool-free assembly in minutes</li><li>Ergonomically designed for superior comfort</li></ul>',
    },
    {
      id: 6,
      name: "Modern couch",
      price: 490,
      price_with_discount: 490,
      discount_percentage: 0,
      is_new: true,
      model: "TF003 -2",
      images: [
        "https://cdn.salla.sa/mmgvo/5519e395-c80f-47e5-87b8-de3440348389-500x500-Gz9nUSajuM4LEgesWZLuY1fyb2c3IGPkppjRpYEq.jpg",
      ],
      description:
        '<p><strong>Modern Elegance Sofa</strong></p><p>Transform your living space with this sleek, <em>modern elegance sofa</em> designed to blend style and comfort effortlessly. Upholstered in high-quality fabric, this sofa features clean lines, tapered wooden legs, and plush cushions that provide the perfect balance of firm support and soft coziness. Whether you\'re entertaining guests or enjoying a cozy night in, this sofa is versatile enough to suit any occasion. Its neutral color palette complements any decor, while the minimalist design adds a touch of sophistication to your home.</p><p><strong>Dimensions:</strong> 85" W x 35" D x 32" H</p><p><strong>Material:</strong> Premium polyester fabric, hardwood frame, foam cushions</p><p><strong>Key Features:</strong></p><ul><li>Removable cushion covers for easy cleaning</li><li>Stain-resistant fabric</li><li>Durable hardwood frame</li><li>Tool-free assembly in minutes</li><li>Ergonomically designed for superior comfort</li></ul>',
    },
    {
      id: 7,
      name: "Bedding set 4 pieces",
      price: 6990,
      price_with_discount: 2990,
      discount_percentage: 60,
      is_new: false,
      model: "TF003 -2",
      images: [
        "https://cdn.salla.sa/mmgvo/c84d15fc-8671-4827-afc5-39ef78a238d0-500x500-owOwhFyUPQdiNWoAYhVvK5PVrf4KM0nt7k3tHPQv.jpg",
      ],
      description:
        '<p><strong>Modern Elegance Sofa</strong></p><p>Transform your living space with this sleek, <em>modern elegance sofa</em> designed to blend style and comfort effortlessly. Upholstered in high-quality fabric, this sofa features clean lines, tapered wooden legs, and plush cushions that provide the perfect balance of firm support and soft coziness. Whether you\'re entertaining guests or enjoying a cozy night in, this sofa is versatile enough to suit any occasion. Its neutral color palette complements any decor, while the minimalist design adds a touch of sophistication to your home.</p><p><strong>Dimensions:</strong> 85" W x 35" D x 32" H</p><p><strong>Material:</strong> Premium polyester fabric, hardwood frame, foam cushions</p><p><strong>Key Features:</strong></p><ul><li>Removable cushion covers for easy cleaning</li><li>Stain-resistant fabric</li><li>Durable hardwood frame</li><li>Tool-free assembly in minutes</li><li>Ergonomically designed for superior comfort</li></ul>',
    },
  ],
};

export const dummyCategories = [
  {
    id: 1,
    name: "Kitchen",
    image: "",
    categories: [
      {
        id: 2,
        name: "Serving plates",
        image: "",
        categories: [
          {
            id: 3,
            name: "Tea serving plates",
            image: "",
          },
          {
            id: 4,
            name: "Cups",
            image: "",
          },
        ],
      },
      {
        id: 5,
        name: "Baskets",
        image: "",
      },
      {
        id: 6,
        name: "Cooking",
        image: "",
        categories: [
          {
            id: 7,
            name: "Pans",
            image: "",
          },
          {
            id: 8,
            name: "Cake mold",
            image: "",
          },
        ],
      },
    ],
  },
  {
    id: 9,
    name: "Outside the house",
    image: "",
    categories: [
      {
        id: 10,
        name: "Tents",
        image: "",
      },
      {
        id: 11,
        name: "Swimming",
        image: "",
      },
    ],
  },
  dummyCategory,
  {
    id: 13,
    name: "Games",
    image: "",
  },
  {
    id: 14,
    name: "Swimming",
    image: "",
  },
  {
    id: 15,
    name: "Bags",
    image: "",
  },
  {
    id: 16,
    name: "Best deals",
    image: "",
  },
  {
    id: 17,
    name: "Home electronics",
    image: "",
  },
  {
    id: 18,
    name: "Furniture",
    image: "",
  },
  // {
  //   id: 19,
  //   name: "Back to school",
  //   image: "",
  // },
  // {
  //   id: 20,
  //   name: "Car",
  //   image: "",
  // },
  // {
  //   id: 21,
  //   name: "Home electronics",
  //   image: "",
  // },
  // {
  //   id: 22,
  //   name: "Home electronics",
  //   image: "",
  // },
  // {
  //   id: 23,
  //   name: "Home electronics",
  //   image: "",
  // },
];

export const dummyCart = [
  {
    id: 1,
    quantity: 2,
    subtotal: 980,
    product: {
      id: 1,
      name: "Modern couch",
      price: 490,
      price_with_discount: 490,
      discount_percentage: 0,
      is_new: true,
      model: "TF003 -2",
      images: [
        "https://cdn.salla.sa/mmgvo/5519e395-c80f-47e5-87b8-de3440348389-500x500-Gz9nUSajuM4LEgesWZLuY1fyb2c3IGPkppjRpYEq.jpg",
        "https://cdn.salla.sa/mmgvo/90ebe4ec-8c0a-4148-b6bf-fa60e3beea46-1000x1000-MN0dvHQDqVVSmoOSTeNv71bB3pK2rZxjPjrlRa19.jpg",
        "https://cdn.salla.sa/mmgvo/90ebe4ec-8c0a-4148-b6bf-fa60e3beea46-1000x1000-MN0dvHQDqVVSmoOSTeNv71bB3pK2rZxjPjrlRa19.jpg",
        "https://cdn.salla.sa/mmgvo/90ebe4ec-8c0a-4148-b6bf-fa60e3beea46-1000x1000-MN0dvHQDqVVSmoOSTeNv71bB3pK2rZxjPjrlRa19.jpg",
        "https://cdn.salla.sa/mmgvo/90ebe4ec-8c0a-4148-b6bf-fa60e3beea46-1000x1000-MN0dvHQDqVVSmoOSTeNv71bB3pK2rZxjPjrlRa19.jpg",
        "https://cdn.salla.sa/mmgvo/90ebe4ec-8c0a-4148-b6bf-fa60e3beea46-1000x1000-MN0dvHQDqVVSmoOSTeNv71bB3pK2rZxjPjrlRa19.jpg",
        "https://cdn.salla.sa/mmgvo/90ebe4ec-8c0a-4148-b6bf-fa60e3beea46-1000x1000-MN0dvHQDqVVSmoOSTeNv71bB3pK2rZxjPjrlRa19.jpg",
        "https://cdn.salla.sa/mmgvo/90ebe4ec-8c0a-4148-b6bf-fa60e3beea46-1000x1000-MN0dvHQDqVVSmoOSTeNv71bB3pK2rZxjPjrlRa19.jpg",
        "https://cdn.salla.sa/mmgvo/90ebe4ec-8c0a-4148-b6bf-fa60e3beea46-1000x1000-MN0dvHQDqVVSmoOSTeNv71bB3pK2rZxjPjrlRa19.jpg",
      ],
      description:
        '<p><strong>Modern Elegance Sofa</strong></p><p>Transform your living space with this sleek, <em>modern elegance sofa</em> designed to blend style and comfort effortlessly. Upholstered in high-quality fabric, this sofa features clean lines, tapered wooden legs, and plush cushions that provide the perfect balance of firm support and soft coziness. Whether you\'re entertaining guests or enjoying a cozy night in, this sofa is versatile enough to suit any occasion. Its neutral color palette complements any decor, while the minimalist design adds a touch of sophistication to your home.</p><p><strong>Dimensions:</strong> 85" W x 35" D x 32" H</p><p><strong>Material:</strong> Premium polyester fabric, hardwood frame, foam cushions</p><p><strong>Key Features:</strong></p><ul><li>Removable cushion covers for easy cleaning</li><li>Stain-resistant fabric</li><li>Durable hardwood frame</li><li>Tool-free assembly in minutes</li><li>Ergonomically designed for superior comfort</li></ul>',
    },
  },
  {
    id: 2,
    quantity: 1,
    subtotal: 2990,
    product: {
      id: 2,
      name: "Bedding set 4 pieces",
      price: 6990,
      price_with_discount: 2990,
      discount_percentage: 60,
      is_new: false,
      model: "TF003 -2",
      images: [
        "https://cdn.salla.sa/mmgvo/c84d15fc-8671-4827-afc5-39ef78a238d0-500x500-owOwhFyUPQdiNWoAYhVvK5PVrf4KM0nt7k3tHPQv.jpg",
      ],
      description:
        '<p><strong>Modern Elegance Sofa</strong></p><p>Transform your living space with this sleek, <em>modern elegance sofa</em> designed to blend style and comfort effortlessly. Upholstered in high-quality fabric, this sofa features clean lines, tapered wooden legs, and plush cushions that provide the perfect balance of firm support and soft coziness. Whether you\'re entertaining guests or enjoying a cozy night in, this sofa is versatile enough to suit any occasion. Its neutral color palette complements any decor, while the minimalist design adds a touch of sophistication to your home.</p><p><strong>Dimensions:</strong> 85" W x 35" D x 32" H</p><p><strong>Material:</strong> Premium polyester fabric, hardwood frame, foam cushions</p><p><strong>Key Features:</strong></p><ul><li>Removable cushion covers for easy cleaning</li><li>Stain-resistant fabric</li><li>Durable hardwood frame</li><li>Tool-free assembly in minutes</li><li>Ergonomically designed for superior comfort</li></ul>',
    },
  },
];
