import React, { useState, useEffect, useRef } from "react";
import "../../assets/styles/photo_slider.css";

export const PhotoSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalIdRef = useRef(null);

  useEffect(() => {
    const startInterval = () => {
      intervalIdRef.current = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 4000);
    };

    startInterval();

    return () => clearInterval(intervalIdRef.current);
  }, [images.length]);

  const handleImageClick = (index) => {
    setCurrentIndex(index);
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }
    intervalIdRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);
  };

  return (
    <div className="flex flex-row h-[400px] md:h-[600px]">
      <div className="flex flex-col space-y-2 px-2 overflow-y-auto">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Preview ${index}`}
            className={`w-[70px] md:w-[100px] lg:w-[150px] object-cover cursor-pointer ${
              index === currentIndex ? "border-2 border-primary" : ""
            }`}
            onClick={() => handleImageClick(index)}
          />
        ))}
      </div>
      <div className="relative w-full lg:w-[600px] pt-[50px] overflow-hidden">
        {images.map((image, index) => (
          <div
            key={index}
            className={`absolute transition-transform transform w-full ${
              index === currentIndex
                ? "animate-slide-in"
                : index === (currentIndex - 1 + images.length) % images.length
                ? "animate-slide-out"
                : "opacity-0 pointer-events-none"
            }`}
          >
            <img
              src={image}
              alt={`Slide ${index}`}
              className="w-full object-cover"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
