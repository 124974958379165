export function findParentCategory(categories, targetId, parent = null) {
  for (let category of categories) {
    if (category.id === targetId) {
      return parent;
    }
    if (category.categories && category.categories.length > 0) {
      const result = findParentCategory(
        category.categories,
        targetId,
        category
      );
      if (result) {
        return result;
      }
    }
  }
  return null;
}
