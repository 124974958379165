import React from "react";
import { Layout } from "../../components/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { dummyCategories } from "../../util/dummy";
import { ItemCategoryProduct } from "./ItemCategoryProduct";
import Dropdown from "../../components/Dropdown";

export const CategoryPage = () => {
  const { categoryId } = useParams();
  const navigate = useNavigate();

  const allCategories = dummyCategories;
  const category = allCategories.find((c) => c.id === Number(categoryId));

  if (!category) {
    //TODO: redirect to error page
    navigate("/");
    return null;
  }

  return (
    <Layout>
      <div className="flex flex-col px-4 py-8 space-y-4">
        <div className="flex flex-row justify-between items-center">
          <div className="text-2xl font-bold text-text">{category.name}</div>
          <div className="flex flex-row items-center space-x-2">
            <div className="text-text text-sm lg:text-lg">Sort</div>
            <Dropdown
              items={[
                "Our recommendations",
                "Best sellers",
                "Highest rated",
                "Price high to low",
                "Price low to high",
              ]}
              onItemSelected={(item) => {}}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full">
          {category.products.map((product, index) => (
            <ItemCategoryProduct
              key={index}
              product={product}
              onHoverChange={() => {}}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};
