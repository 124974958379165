import React, { useState } from "react";
import { iosArrowDown } from "../util/ui_util";
import colors from "../util/colors";

const Dropdown = ({ items, onItemSelected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(items[0]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsOpen(false);
    onItemSelected(item);
  };

  return (
    <div className="relative inline-block text-left">
      <button
        onClick={toggleDropdown}
        className="inline-flex justify-center w-48 rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-text hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-primary"
      >
        <div className="flex flex-row w-full justify-between items-center">
          {selectedItem}
          {iosArrowDown({ fillColor: colors.primary })}
        </div>
      </button>
      {isOpen && (
        <ul className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-gray-800 backdrop-blur-sm opacity-70 z-30">
          {items.map((item, index) => (
            <li
              key={index}
              className="px-4 py-2 text-sm text-white hover:bg-gray-600 cursor-pointer"
              onClick={() => {
                handleItemClick(item);
              }}
            >
              {selectedItem === item ? `✓ ${item}` : item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
