import React, { useRef } from "react";
import { Layout } from "../../components/Layout";
import { dummyCategory } from "../../util/dummy";
import { PhotoSlider } from "./PhotoSlider";
import { useParams, useNavigate } from "react-router-dom";
import {
  getFormattedPrice,
  iconBag,
  iconBarcode,
  iconCheck,
  iconHeartEmpty,
  iconShare,
  iconWallet,
} from "../../util/ui_util";
import { ButtonWithIcon } from "../../components/ButtonWithIcon";
import colors from "../../util/colors";
import "../../assets/styles/product_page.css";
import { ProductQuantity } from "./ProductQuantity";
import { ItemHomeProduct } from "../home_page/ItemHomeProduct";
import { useDraggable } from "react-use-draggable-scroll";

export const ProductPage = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const ref = useRef(null);
  const { events } = useDraggable(ref);

  const allProducts = dummyCategory.products;
  const product = allProducts.find((p) => p.id === Number(productId));

  if (!product) {
    //TODO: redirect to error page
    navigate("/");
    return null;
  }

  return (
    <Layout>
      <div className="flex flex-col px-4 py-8 space-y-16">
        <div className="flex flex-col lg:flex-row w-full lg:space-x-4">
          <PhotoSlider images={product.images} />
          <div className="flex flex-col w-full space-y-2">
            <div className="flex flex-row justify-end items-center lg:space-x-2">
              {iconShare({
                fillColor: colors.text,
                width: "1.5em",
                height: "1.5em",
              })}
              {iconHeartEmpty({
                fillColor: colors.text,
                width: "1.5em",
                height: "1.5em",
              })}
            </div>
            <div className="flex flex-col">
              <div className="text-text font-bold text-2xl">{product.name}</div>
              <div className="text-text font-bold text-xl">
                {getFormattedPrice(product.price)}
              </div>
              <div className="flex flex-row space-x-1 items-center">
                <div className="icon-container">
                  <div className="pulsing-circle"></div>
                  <div className="z-20">
                    {iconCheck({ fillColor: "#22c55e", width: "24px" })}
                  </div>
                </div>
                <div className="text-green-500 pt-1 font-bold">
                  Available now!
                </div>
              </div>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: product.description }}
            ></div>
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row space-x-1">
                {iconBarcode({ fillColor: colors.text })}
                <div className="font-bold text-text text-l">Model</div>
              </div>
              <div className="font-bold text-text text-l">{product.model}</div>
            </div>
            <div className="flex flex-row justify-between items-center">
              <div className="font-bold text-text text-l">Price</div>
              <div className="font-bold text-text text-l">
                {getFormattedPrice(product.price)}
              </div>
            </div>
            <ProductQuantity />
            <div className="flex flex-row w-full space-x-2">
              <ButtonWithIcon
                text="Add to cart"
                textColor="#ffffff"
                textHoverColor="#ffffff"
                bgColor={colors.primary}
                bgHoverColor={colors.primary}
                getIcon={(color) => {
                  return iconBag({
                    fillColor: color,
                    width: "1.5rem",
                    height: "1.5rem",
                  });
                }}
              />
              <ButtonWithIcon
                text="Buy now"
                textColor={colors.primary}
                textHoverColor="#ffffff"
                bgColor="transparent"
                bgHoverColor={colors.primary}
                getIcon={(color) => {
                  return iconWallet({
                    fillColor: color,
                    width: "1.5rem",
                    height: "1.5rem",
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="font-bold text-text text-lg lg:text-2xl">Related products</div>
          <div>
            <div className="border border-primary w-[180px]  lg:w-[240px]"></div>
            <div className="border border-gray-300 w-full"></div>
          </div>
          <div
            className="flex w-screen overflow-x-scroll scrollbar-hide space-x-4"
            {...events}
            ref={ref}
          >
            {allProducts.map((product, index) => (
              <ItemHomeProduct
                key={index}
                product={product}
                mobileWidth={144}
                onHoverChange={() => {}}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};
