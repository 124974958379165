import React, { useState, useEffect } from "react";

export const HomeSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <div className="relative w-full pb-[40.83%]">
      {/* Aspect Ratio 1200x490 */}
      {images.map((image, index) => (
        <div
          key={index}
          className={`absolute transition-transform transform w-full h-full ${
            index === currentIndex
              ? "animate-slide-in"
              : index === (currentIndex - 1 + images.length) % images.length
              ? "animate-slide-out"
              : "opacity-0 pointer-events-none"
          }`}
        >
          <img
            src={image}
            alt={`Slide ${index}`}
            className="w-full h-full object-cover rounded-2xl"
          />
        </div>
      ))}
    </div>
  );
};
