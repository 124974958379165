import React, { useState, useEffect, useRef } from "react";
import { ItemHomeProduct } from "./ItemHomeProduct";
import { useDraggable } from "react-use-draggable-scroll";

export const HomeCategory = ({ category }) => {
  const ref = useRef(null);
  const { events } = useDraggable(ref);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    if (isMobile) return;
    const interval = setInterval(() => {
      if (!isPaused && ref.current) {
        const maxScrollLeft = ref.current.scrollWidth - ref.current.clientWidth;
        const itemWidth = ref.current.firstChild?.clientWidth || 0;
        setScrollPosition((prev) => {
          const newScrollPosition = prev + itemWidth;
          if (newScrollPosition >= maxScrollLeft) {
            return 0;
          }
          return newScrollPosition;
        });
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [isPaused, isMobile]);

  useEffect(() => {
    if (ref.current && !isMobile) {
      ref.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [scrollPosition, isMobile]);

  const handleHoverState = (isHovering) => {
    setIsPaused(isHovering);
  };

  return (
    <div className="w-full flex flex-col justify-center items-center space-y-2">
      <div className="flex flex-col items-center justify-center">
        <div className="font-bold text-3xl text-[#555555]">{category.name}</div>
        <div className="flex flex-row items-center space-x-4">
          <div className="bg-primary h-[2px] w-[110px]"></div>
          <div className="border-primary border-2 rounded-full h-[14px] w-[14px]"></div>
          <div className="bg-primary h-[2px] w-[110px]"></div>
        </div>
      </div>
      <div
        className="flex w-full overflow-x-scroll scrollbar-hide space-x-4 p-4"
        {...events}
        ref={ref}
      >
        {category.products.map((product, index) => (
          <ItemHomeProduct
            key={index}
            product={product}
            onHoverChange={handleHoverState}
          />
        ))}
      </div>
    </div>
  );
};
