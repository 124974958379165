import React, { useState } from "react";
import {
  getFormattedPrice,
  iconBag,
  iconEye,
  iconHeartEmpty,
} from "../../util/ui_util";
import { ItemHomeProductButton } from "./ItemHomeProductButton";
import colors from "../../util/colors";
import { ButtonWithIcon } from "../../components/ButtonWithIcon";
import { Link } from "react-router-dom";

export const ItemHomeProduct = ({ product, onHoverChange }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isViewLoading, setIsViewLoading] = useState(false);
  const [isAddToCartLoading, setAddToCartLoading] = useState(false);

  const handleViewClick = async (e) => {
    setIsViewLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsViewLoading(false);
  };

  const handleAddToCartClick = async (e) => {
    setAddToCartLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setAddToCartLoading(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    onHoverChange(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    onHoverChange(false);
  };

  return (
    <Link to={`/product/${product.id}`} draggable={false}>
      <div
        className={`flex-none flex-col w-[144px] lg:w-[300px] items-center cursor-pointer`}
      >
        <div
          className="relative w-full overflow-hidden hidden lg:block"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <img
            src={product.images[0]}
            alt=""
            className={`w-full object-contain transition-transform duration-300 ${
              isHovered ? "scale-110" : "scale-100"
            }`}
            draggable={false}
          />
          <div
            className={`flex flex-col absolute bottom-0 left-0 right-0 bg-white py-2 space-y-2 items-center transition-all duration-300 ${
              isHovered ? "h-[140px]" : "h-[90px]"
            }`}
          >
            <div className="font-bold  text-text hover:text-primary">
              {product.name}
            </div>
            <div className="flex flex-col items-center">
              <div className="flex flex-row items-center justify-center space-x-2">
                <div
                  className={`${
                    product.price !== product.price_with_discount
                      ? "text-red-500"
                      : "text-black"
                  } ${
                    product.price !== product.price_with_discount
                      ? "font-bold"
                      : ""
                  } text-sm`}
                >
                  {getFormattedPrice(product.price_with_discount)}
                </div>
                <div
                  className={`${
                    product.price !== product.price_with_discount
                      ? "block"
                      : "hidden"
                  } ${
                    product.price !== product.price_with_discount
                      ? "line-through"
                      : ""
                  } text-sm`}
                >
                  {getFormattedPrice(product.price)}
                </div>
              </div>
              <div
                className={`${
                  product.price !== product.price_with_discount
                    ? "block"
                    : "hidden"
                } text-red-500 text-sm font-bold`}
              >
                {`${product.discount_percentage}% Discount`}
              </div>
            </div>
            {isHovered && (
              <div className="flex flex-row items-center space-x-2 mt-4">
                <ItemHomeProductButton
                  icon={iconHeartEmpty({
                    fillColor: colors.primary,
                    width: "1.5rem",
                    height: "1.5rem",
                  })}
                  iconFilled={iconHeartEmpty({
                    fillColor: "#ffffff",
                    width: "1.5rem",
                    height: "1.5rem",
                  })}
                  onClick={() => {}}
                />
                <ItemHomeProductButton
                  icon={iconEye({
                    fillColor: colors.primary,
                    width: "1.5rem",
                    height: "1.5rem",
                  })}
                  iconFilled={iconEye({
                    fillColor: "#ffffff",
                    width: "1.5rem",
                    height: "1.5rem",
                  })}
                  isLoading={isViewLoading}
                  onClick={handleViewClick}
                />
                <ItemHomeProductButton
                  icon={iconBag({
                    fillColor: colors.primary,
                    width: "1.5rem",
                    height: "1.5rem",
                  })}
                  iconFilled={iconBag({
                    fillColor: "#ffffff",
                    width: "1.5rem",
                    height: "1.5rem",
                  })}
                  isLoading={isAddToCartLoading}
                  onClick={handleAddToCartClick}
                />
              </div>
            )}
          </div>
        </div>
        {/* md layout */}
        <div className="relative w-full overflow-hidden block lg:hidden">
          <img
            src={product.images[0]}
            alt=""
            className={`w-full h-[272px] object-contain`}
            draggable={false}
          />
          <div className="flex w-[32px] top-0 right-0 left-0 absolute">
            <ItemHomeProductButton
              icon={iconHeartEmpty({
                fillColor: colors.primary,
                width: "1.5rem",
                height: "1.5rem",
              })}
              iconFilled={iconHeartEmpty({
                fillColor: "#ffffff",
                width: "1.5rem",
                height: "1.5rem",
              })}
              onClick={() => {}}
            />
          </div>
          <div
            className={`flex flex-col bottom-0 left-0 right-0 bg-white p-2 justify-between items-center h-[160px]`}
          >
            <div className="flex flex-col items-center">
              <div className="font-bold text-text hover:text-primary text-center">
                {product.name}
              </div>
              <div className="flex flex-row items-center justify-center space-x-2">
                <div
                  className={`${
                    product.price !== product.price_with_discount
                      ? "text-red-500"
                      : "text-black"
                  } ${
                    product.price !== product.price_with_discount
                      ? "font-bold"
                      : ""
                  } text-sm`}
                >
                  {getFormattedPrice(product.price_with_discount)}
                </div>
                <div
                  className={`${
                    product.price !== product.price_with_discount
                      ? "block"
                      : "hidden"
                  } ${
                    product.price !== product.price_with_discount
                      ? "line-through"
                      : ""
                  } text-sm`}
                >
                  {getFormattedPrice(product.price)}
                </div>
              </div>
              <div
                className={`${
                  product.price !== product.price_with_discount
                    ? "block"
                    : "hidden"
                } text-red-500 text-sm font-bold`}
              >
                {`${product.discount_percentage}% Discount`}
              </div>
            </div>
            <ButtonWithIcon
              text="Add to cart"
              textColor={colors.primary}
              textHoverColor="#ffffff"
              bgColor="transparent"
              bgHoverColor={colors.primary}
              getIcon={(color) => {
                return iconBag({
                  fillColor: color,
                  width: "1.5rem",
                  height: "1.5rem",
                });
              }}
              isLoading={isAddToCartLoading}
              onClick={handleAddToCartClick}
            />
          </div>
        </div>
      </div>
    </Link>
  );
};
