import React, { useState } from "react";
import "../assets/styles/banner.css";
import { dummyBannerData } from "../util/dummy";

export const Banner = () => {
  const [isPaused, setIsPaused] = useState(false);
  const banners = dummyBannerData;

  return (
    <div className="bg-primary top-0 w-screen z-50">
      <div
        className={`overflow-hidden whitespace-nowrap ${
          isPaused ? "paused" : ""
        }`}
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
      >
        <div className="animate-marquee py-3">
          {banners.map((banner) => (
            <a href={banner.url} className="mx-4 text-white" key={banner.id}>
              {banner.text}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
