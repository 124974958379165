import React, { useState } from "react";
import { iosArrowDown } from "../util/ui_util";
import { ItemNavigationSubcategory } from "./ItemNavigationSubcategory";
import { Link } from "react-router-dom";

export const ItemNavigation = ({ category, isRtl }) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasSubcategories =
    category?.categories && category.categories.length > 0;

  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);

  return (
    <div
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`flex items-center space-x-1 ${
          isOpen ? "text-primary" : "text-black"
        } cursor-pointer font-bold`}
      >
        <Link to={`/category/${category.id}`} draggable={false}>
          {category?.name}
        </Link>
        {hasSubcategories && (
          <span
            className={`ml-2 transform transition-transform ${
              isOpen ? "rotate-180" : "rotate-0"
            }`}
          >
            {iosArrowDown()}
          </span>
        )}
      </div>
      {isOpen && hasSubcategories && (
        <div
          className={`absolute w-[250px] bg-white shadow-lg z-50 ${
            isRtl ? "right-0" : "left-0"
          }`}
        >
          {category.categories?.map((sub, index) => (
            <ItemNavigationSubcategory
              key={index}
              category={sub}
              isRtl={isRtl}
            />
          ))}
        </div>
      )}
    </div>
  );
};
