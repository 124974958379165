import React, { useState } from "react";
import { MoonLoader } from "react-spinners";

export const ButtonWithIcon = ({
  text,
  type,
  getIcon,
  bgColor,
  bgHoverColor,
  textColor,
  textHoverColor,
  isLoading,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const spinner = () => (
    <MoonLoader
      color={isHovered ? textHoverColor : textColor}
      loading={true}
      size={18}
    />
  );

  const handleClick = (e) => {
    if (isLoading) {
      e.preventDefault();
      return;
    }
    if (type !== "submit") {
      onClick();
    }
  };

  return (
    <div
      className={`flex flex-row w-full items-center justify-center p-1 rounded border-2 cursor-pointer`}
      style={{
        backgroundColor: isHovered ? bgHoverColor : bgColor,
        borderColor: bgHoverColor,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      {isLoading ? (
        spinner()
      ) : (
        <div className="flex flex-row justify-center items-center space-x-2">
          {isHovered ? (
            <span>{getIcon(textHoverColor)}</span>
          ) : (
            <span>{getIcon(textColor)}</span>
          )}
          <div
            className={`text-sm pt-1`}
            style={{ color: isHovered ? textHoverColor : textColor }}
          >
            {text}
          </div>
        </div>
      )}
    </div>
  );
};
