import React, { useState, useEffect } from "react";
import { ItemNavigation } from "./ItemNavigation";
import { iconBurgerMenu } from "../util/ui_util";
import logo from "../assets/images/logo.webp";
import iconSearch from "../assets/images/icon_search.svg";
import iconUser from "../assets/images/icon_user.svg";
import iconCart from "../assets/images/icon_cart.svg";
import { NavLink, useNavigate } from "react-router-dom";

export const NavigationBar = ({
  isDesktop,
  isRtl,
  isOpen,
  categories,
  onBurgerMenuClick,
}) => {
  const navigate = useNavigate();
  const [shouldShowUser, setShouldShowUser] = useState(isDesktop);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1024px)");
    const handleMediaQueryChange = (event) => {
      if (event.matches) {
        setShouldShowUser(true);
      } else {
        setShouldShowUser(false);
      }
    };
    mediaQuery.addEventListener("change", handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  const buttonSearch = (
    <img
      src={iconSearch}
      alt=""
      className="w-[20px] cursor-pointer"
      onClick={() => {}}
    />
  );

  const buttonUser = (
    <img
      src={iconUser}
      alt=""
      className="w-[20px] cursor-pointer"
      onClick={() => {}}
    />
  );

  const buttonCart = (
    <img
      src={iconCart}
      alt=""
      className="w-[20px] cursor-pointer"
      onClick={() => {
        navigate("/cart");
      }}
    />
  );

  return (
    <nav className={`container mx-auto py-4 bg-white`}>
      <div
        className="flex-row flex justify-between items-center"
        dir={isRtl ? "rtl" : "ltr"}
      >
        {/*START: This right layout appears on md screens */}
        <div className="flex flex-row lg:hidden space-x-4">
          <div className={`${isOpen ? "hidden" : "block"}`}>
            <button
              onClick={onBurgerMenuClick}
              className="text-black focus:outline-none"
            >
              {iconBurgerMenu}
            </button>
          </div>
          {buttonUser}
        </div>
        {/*END: This right layout appears on md screens */}
        <NavLink to={"/"}>
          <img src={logo} alt="logo" className="h-[50px] object-scale-down" />
        </NavLink>
        <div
          className={`flex flex-wrap gap-y-2 gap-x-3 ${
            isRtl ? "space-x-reverse" : ""
          } px-4 items-center w-full hidden lg:inline-flex`}
        >
          {categories.map((category) => {
            return (
              <ItemNavigation
                key={category.id}
                category={category}
                isRtl={isRtl}
              />
            );
          })}
        </div>
        <div
          className={`flex flex-row space-x-4 ${
            isRtl ? "space-x-reverse" : ""
          } pe-4`}
        >
          {buttonSearch}
          {shouldShowUser && buttonUser}
          {buttonCart}
        </div>
      </div>
    </nav>
  );
};
