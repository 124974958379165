const colors = {
    primary: "#BB8713",
    secondary: "#333333",
    accent: "#F59E0B",
    background: "#FCFCFC",
    text: "#555555",
    success: "#10B981",
    warning: "#FBBF24",
    danger: "#EF4444",
    aboutBackground: "#F7F7F7",
    extendedBurgerMenuBackground:'#0E0E0F',
    navbarBackground:'#151936',
    loginDialogBackground: '#F8F9FB',
  };
  
  export default colors;